<template>
  <div  class="sb-page" :class="{'using-title':!is_visual_title}" v-editable="editing && blok">
    <hgroup class="2xl:container px-[25px] lg:px-[2rem] 2xl:px-[0rem] 2xl:mx-auto 2xl:container lg:grid lg:grid-cols-12 gap-x-8">
      <Breadcrumbs />
      <h1 class="font-serif mb-5 pt-[6rem] is-page-title col-start-2 col-span-1 col-end-7" v-if="renderRichText(blok.visual_title).indexOf('h1') < 0" :class="{'hidden':is_visual_title}" >{{blok?.title || parent?.name || 'Page title missing'}}</h1>
      <div class="sb-page__visual-title font-serif  text-8xl" v-if="blok.visual_title" v-html="renderRichText(blok.visual_title)"></div>
    </hgroup>

    <div class="sb-page__content" >
      <StoryblokComponent v-for="blok in blok.body" :key="blok._uid" :blok="blok" />

    </div>
  </div>
</template>

<script setup>
const { blok } = defineProps({ blok: Object,parent:Object })
  const {editing } = useSettings("sb-page");
  let is_visual_title = computed(()=>{
    return blok.visual_title && renderRichText(blok.visual_title) != "<p></p>"
  })
</script>
<style lang="scss">
  // @import '/assets/styles/scss/components/storyblok/page';
  
</style>
